import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mnet-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class LanguageSelectorComponent implements OnInit {
  public currentLanguage = 'de';

  constructor(
    private readonly route: ActivatedRoute,
    public languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.locale && this.languageService.isLanguagePossible(params.locale)) {
        const locale = params.locale || 'en';
        this.languageService.changeLanguage(locale);
        this.currentLanguage = locale;
      }
    });
  }

  changeLanguage(event) {
    this.languageService.changeLanguage(event);
    this.currentLanguage = event;
  }
}
