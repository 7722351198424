import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ContentRoutingModule} from './content-routing.module';
import {ContentComponent} from './content.component';

@NgModule({
  imports: [
    CommonModule,
    ContentRoutingModule
  ],
  declarations: [
    ContentComponent,
  ],
  exports: [
    ContentComponent,
  ]
})
export class ContentModule { }
