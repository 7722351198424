import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {REGISTRATION_ORIGIN} from '../enums/origin.enum';
import { environment } from '../../environments/environment';

const routes: Routes = [
  {
    path: 'form',
    loadChildren: () => import('../components/company-registration/company-registration.module').then(m => m.CompanyRegistrationModule),
    data: {
      origin: REGISTRATION_ORIGIN.DEFAULT
    }
  },
  {
    path: 'kform/:code',
    loadChildren: () => import('../components/company-registration/company-registration.module').then(m => m.CompanyRegistrationModule),
    data: {
      origin: REGISTRATION_ORIGIN.KAUFLAND
    }
  },
  {
    path: 'confirm-administrator/:id',
    loadChildren: () => import('../components/confirm-admin/confirm-admin.module').then(m => m.ConfirmAdminModule),
  },
  {
    path: 'user-form/:id',
    loadChildren: () => {
      if (environment.featureToggleUserRegistration) {
        return import('../components/user-registration/user-registration-routing.module').then(m => m.UserRegistrationRoutingModule)
      }
      return import('../components/company-registration/company-registration.module').then(m => m.CompanyRegistrationModule)
    },
    data: {
      origin: REGISTRATION_ORIGIN.DEFAULT
    }
  },
  {path: '**', loadChildren: () => import('../components/start/start.module').then(m => m.StartModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class ContentRoutingModule {
}
