<markant-select class="language-select"
                outlined="false"
                [value]="currentLanguage"
                (valueChange)="changeLanguage($event.detail)">
  <markant-select-option value="bs">Bosnian</markant-select-option>
  <markant-select-option value="bg">български</markant-select-option>
  <markant-select-option value="cs">Čeština</markant-select-option>
  <markant-select-option value="de">Deutsch</markant-select-option>
  <markant-select-option value="en">English</markant-select-option>
  <markant-select-option value="es">Español</markant-select-option>
  <markant-select-option value="fr">Français</markant-select-option>
  <markant-select-option value="hr">Hrvatski</markant-select-option>
  <markant-select-option value="hu">Magyar</markant-select-option>
  <markant-select-option value="it">Italiano</markant-select-option>
  <markant-select-option value="nl">Nederlands</markant-select-option>
  <markant-select-option value="pl">Polski</markant-select-option>
  <markant-select-option value="ro">Română</markant-select-option>
  <markant-select-option value="sk">Slovensky</markant-select-option>
  <markant-select-option value="sl">Slovenščina</markant-select-option>
  <markant-select-option value="sr">Srpski</markant-select-option>
</markant-select>
