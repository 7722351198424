import {Injectable} from '@angular/core';
import {Subscription} from "rxjs";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import {ActivatedRouteSnapshot, ResolveEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {isNotNullOrUndefined} from "codelyzer/util/isNotNullOrUndefined";

export abstract class TrackingService {
  /**
   * Logs event
   *
   * @param event {
   *   - trackingEvent: string which can used to group events in azure insights
   *   - properties: custom Object which can used to sub group events in azure insights
   * }
   */
  abstract logEvent(event: { trackingEvent: string, properties?: {} }): void;
}

@Injectable({
  providedIn: 'platform'
})
export class TrackingServiceImpl extends TrackingService {
  insightsContextCache = {};
  private routerSubscription: Subscription;
  private appInsights = new ApplicationInsights({
    config: {
      appId: environment.appId,
      instrumentationKey: environment.azureInsightsKey,
      isCookieUseDisabled: true,
    }
  });

  private isDeactivated = !isNotNullOrUndefined(environment.azureInsightsKey);

  constructor(private router: Router) {
    super();
    this.initTracking();
  }

  private static initializeApplicationInsightsUser(userId: string, accountId: string | number, appInsights: ApplicationInsights) {
    appInsights.context.user.id = userId;
    appInsights.context.user.accountId = accountId + "";
    appInsights.setAuthenticatedUserContext(userId, accountId + "")
  }

  logEvent(event: { trackingEvent: string, properties?: {} }): void {
    if (!this.isDeactivated) {
      this.appInsights.trackEvent({
        name: event.trackingEvent.toString(),
        properties: event.properties
      });
    } else {
      console.debug("TRACKING FEATURE IS DEACTIVATED")
    }
  }

  private initTracking() {
    if (!this.isDeactivated) {
      console.info("TRACKING NOT LOGGED IN");
      this.appInsights.loadAppInsights();
      TrackingServiceImpl.initializeApplicationInsightsUser('not_logged_in', 'not_logged_in', this.appInsights);
      this.loadCustomTelemetryProperties(this.appInsights, true);
      this.creatRouterSubscription();
    }
  }

  private logPageView(name?: string, uri?: string): void {
    if (!this.isDeactivated) {
      try {
        this.appInsights.trackPageView({name, uri});
      } catch (e) {
        console.log(e);
      }
    } else {
      console.debug("TRACKING FEATURE IS DEACTIVATED")
    }
  }

  private loadCustomTelemetryProperties(appInsights: ApplicationInsights, trackData: boolean) {
    appInsights.addTelemetryInitializer(envelope => {
      const item = envelope.baseData;
      item.properties = item.properties || {};

      if (trackData) {
        item.properties["ScreenResolution"] = {
          resolution: {
            width: window.screen.width,
            height: window.screen.height,
            orientation: window.screen.orientation.type
          },
          page: {
            width: window.innerWidth,
            height: window.innerHeight,
          }
        };
      }

      item.properties["ApplicationId"] = environment.appId;
    });
  }

  private creatRouterSubscription() {
    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
      const activatedComponent = this.getActivatedComponent(event.state.root);
      if (activatedComponent) {
        this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
      }
    });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
