import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ContentRoutingModule } from './content/content-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentModule } from './content/content.module';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { TrackingService, TrackingServiceImpl } from './services/tracking.service';
import { MarkantElementsModule } from '@markant/elements-angular';
import { environment } from '../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@NgModule({
  declarations: [
    AppComponent,
    LanguageSelectorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ContentRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (httpClient: HttpClient) => new CdnTranslateLoader(httpClient, '.json'),
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
    }),
    ContentModule,
    MarkantElementsModule.forRoot()
  ],
  providers: [
    {provide: TrackingService, useClass: TrackingServiceImpl}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}

class CdnTranslateLoader {
  constructor(private httpClient: HttpClient, private suffix: string) {}

  getTranslation(lang: string): Observable<any> {
    const translationObservables: Observable<any> = this.httpClient.get(`${environment.translationsBaseUrl}${lang}${this.suffix}`);

    return translationObservables.pipe(
      map((result) => result),
      catchError(() => {
        return this.httpClient.get(`assets/i18n/${lang}${this.suffix}`);
      }),
    );
  }
}
