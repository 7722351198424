export const environment = {
  production: false,
  addNoIndex: true,
  defaultLanguage: 'en',
  automaticRegistrationCode: 'utje1nag',
  companyRegistrationServiceUrl: window['env']['companyRegistrationServiceUrl'] || 'http://localhost:9091/mnet/company-registration-service',
  userServiceUrl: window['env']['userServiceUrl'] || 'http://localhost:8090/user-service',
  supportUrl: window['env']['supportUrl'] || 'https://markant-net.markant.services/support',
  teamviewerUrl: window['env']['teamviewerUrl'] || 'https://markant-net.markant.services/teamviewer',
  legalNoticeUrl: window['env']['legalNoticeUrl'] || 'https://markant-net.markant.services/legal-notice',
  dataPrivacyUrl: window['env']['dataPrivacyUrl'] || 'https://markant-net.markant.services/data-privacy',
  translationsBaseUrl: window['env']['translationsBaseUrl'] || 'https://i18n.cdn.markant.services/registration-dev/',
  reason: 'Selbstregistrierung',

  appId: window['env']['appId'] || '',
  azureInsightsKey: window['env']['azureInsightsKey'] || '',

  featureToggleUserRegistration: window['env']['featureToggleUserRegistration'] == 'true' || false
};
