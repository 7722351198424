<markant-header dashboard-icon="false">
    <a href="https://markant-net.markant.services/" slot="logoBar">
        <markant-header-logo-bar
        logo-url="assets/images/logo.svg">
        </markant-header-logo-bar>
    </a>
    <mnet-language-selector slot="mainItems" class="text-link"></mnet-language-selector>
    <a slot="mainItems" href="https://markant.com" class="text-decoration-none d-none d-lg-block">markant.com</a>
</markant-header>

<markant-support-button *ngIf="origin === 'DEFAULT'" text="{{supportTranslation}}" heading="{{'support' | translate}}" round>
    <markant-support-button-item label="{{'support.hotline' | translate}}" icon-url="assets/images/phone-white.svg" link="tel:  +49 (0) 781-616222"
        info="+49 781-616222">
    </markant-support-button-item>
    <markant-support-button-item label="{{'support.mail' | translate}}" icon-url="assets/images/e-mail-white.svg" link="https://markant-net.markant.services/support"
        info="{{'support.form' | translate}}" arrow="true">
    </markant-support-button-item>
    <markant-support-button-item label="{{'support.remote' | translate}}" icon-url="assets/images/teamviewer.svg" link="https://markant-net.markant.services/teamviewer"
        info="{{'support.teamviewer' | translate}}">
    </markant-support-button-item>
</markant-support-button>

<!-- Customized support button for Kaufland suppliers -->
<markant-support-button *ngIf="origin !== 'DEFAULT'" text="{{'support' | translate}}" heading="{{'support' | translate}}" round>
    <markant-support-button-item label="{{'support.hotline' | translate}}" icon-url="assets/images/phone-white.svg" link="tel:{{'support.hotline.nr.' + origin | translate}}"
        info="{{'support.hotline.nr.' + origin | translate}}">
    </markant-support-button-item>
    <markant-support-button-item label="{{'support.mail' | translate}}" icon-url="assets/images/e-mail-white.svg" link="mailto:{{'support.mail.address.' + origin | translate}}"
        info="{{'support.mail' | translate}}" arrow="true">
    </markant-support-button-item>
</markant-support-button>

<mnet-content></mnet-content>

<markant-footer>
    <a slot="content" class="text-decoration-none footer-link" rel="noopener noreferrer"
    [href]="legalNoticeUrl" target="_blank">{{'legal-notice' | translate}}</a>
    <a slot="content" class="text-decoration-none footer-link" rel="noopener noreferrer"
    [href]="dataPrivacyUrl" target="_blank">{{'data-privacy' | translate}}</a>
</markant-footer>
