import {HostListener, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class LanguageService {

  static possibleLanguages: { key: string, value: string }[] = [
    {key: 'de', value: 'Deutsch'},
    {key: 'en', value: 'English'},
    {key: 'fr', value: 'Français'},
    {key: 'cs', value: 'Čeština'},
    {key: 'it', value: 'Italiano'},
    {key: 'pl', value: 'Polski'},
    {key: 'sk', value: 'Slovensky'},
    {key: 'hr', value: 'Hrvatski'},
    {key: 'hu', value: 'Magyar'},
    {key: 'bg', value: 'български'},
    {key: 'bs', value: 'Bosnian'},
    {key: 'ro', value: 'Română'},
    {key: 'sl', value: 'Slovenščina'},
    {key: 'sr', value: 'Srpski'},
    {key: 'nl', value: 'Nederlands'},
    {key: 'es', value: 'Español'}
  ];

  constructor(private translateService: TranslateService) {
  }

  @HostListener('window:onlanguagechange', ['$event'])
  onLanguageChange(): void {
    if (this.isLanguagePossible(this.translateService.getBrowserLang())) {
      this.changeLanguage(this.translateService.getBrowserLang());
    }
  }

  /**
   * Sets default language and sets the language to use
   */
  initLanguage() {
    this.translateService.setDefaultLang(this.getDefaultLanguage());
    this.translateService.use(this.getDefaultLanguage());
  }

  /**
   * Change language
   * @param language
   */
  changeLanguage(language: string): void {
    this.translateService.use(language);
  }

  /**
   * Returns default language
   */
  public getDefaultLanguage(): string {
    return this.translateService.getBrowserLang() && this.isLanguagePossible(this.translateService.getBrowserLang()) ? this.translateService.getBrowserLang() : this.translateService.getDefaultLang() ? this.translateService.getDefaultLang() : environment.defaultLanguage;
  }

  /**
   * Returns the current selected language
   */
  public getUserLanguage(): string {
    return this.translateService.currentLang ? this.translateService.currentLang : this.translateService.defaultLang;
  }

  public isLanguagePossible(key: string) {
    return LanguageService.possibleLanguages.find(value => value.key === key);
  }
}
