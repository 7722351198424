import { Component } from '@angular/core';
import { LanguageService } from './services/language.service';
import { environment } from '../environments/environment';
import { ActivationEnd, Router } from '@angular/router';
import { REGISTRATION_ORIGIN } from './enums/origin.enum';
import { TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'mnet-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  dataPrivacyUrl = environment.dataPrivacyUrl;
  legalNoticeUrl = environment.legalNoticeUrl;
  supportTranslation = 'Support';

  origin: REGISTRATION_ORIGIN = REGISTRATION_ORIGIN.DEFAULT;

  constructor(private languageService: LanguageService,
              private router: Router,
              private translateService: TranslateService,
              private readonly metaService: Meta) {
    this.languageService.initLanguage();
    this.handleNoIndexTag();
    this.router.events.subscribe(e => {
      if (e instanceof ActivationEnd) {
        this.origin = (e.snapshot.data.origin ? e.snapshot.data.origin as REGISTRATION_ORIGIN : REGISTRATION_ORIGIN.DEFAULT);
      }
    });
    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('support').subscribe(translation => {
        this.supportTranslation = translation;
      });
    });
  }

  private handleNoIndexTag(): void {
    if (environment.addNoIndex) {
      this.metaService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    } else {
      this.metaService.removeTag('name=\'robots\'');
    }
  }
}
